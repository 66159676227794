/* eslint-disable operator-linebreak */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['heatingKinds', 'heatingFuels', 'operationKindSelect', 'warranties'];

  connect() {
    if (this.hasHeatingFuelsTarget) {
      this.filterHeatingKind();
    }
    this.toggleWarranties();
  }

  toggleWarranties() {
    if (
      this.operationKindSelectTarget.value === 'rent' ||
      this.operationKindSelectTarget.value === 'rent_and_sell'
    ) {
      $(this.warrantiesTarget).removeClass('d-none');
    } else {
      $(this.warrantiesTarget).addClass('d-none');
    }
  }

  filterHeatingKind() {
    if (this.heatingKindsTarget.value === 'none') {
      this.heatingFuelsTarget.options[0].selected = true;
      this.heatingFuelsTarget.disables = true;
      this.heatingFuelsTarget.classList.add('d-none');
    } else {
      this.heatingFuelsTarget.classList.remove('d-none');
    }
  }
}
