$(document).on('turbo:load', () => {
  const preloader = document.querySelector('.page-loading');
  if (preloader != null) {
    preloader.classList.remove('active');
  }
});

$(document).on('turbo:before-visit', () => {
  const preloader = document.querySelector('.page-loading');
  if (preloader != null) {
    preloader.classList.add('active');
  }
});
