/* global grecaptcha */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static values = { siteKey: String };

  connect() {
    grecaptcha.enterprise.ready(() => {
      grecaptcha.enterprise.render('recaptcha-checkbox', {
        sitekey: this.siteKeyValue,
      });
    });
  }
}
