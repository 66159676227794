/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'addressField',
    'neighborhoodField',
    'hidden',
    'latitude',
    'longitude',
    'country',
    'department',
    'locality',
    'neighborhood',
    'tower_latitude',
    'addressComponents',
  ];

  initMap() {
    const addressOptions = {
      componentRestrictions: { country: 'uy' },
    };

    const neighborhoodOptions = {
      componentRestrictions: { country: 'uy' },
      types: [
        'neighborhood',
        'natural_feature',
        'sublocality',
        'administrative_area_level_2',
        'locality',
      ],
    };

    if (this.hasAddressFieldTarget && !this.autocompleteAddress) {
      this.autocompleteAddress = new google.maps.places.Autocomplete(
        this.addressFieldTarget,
        addressOptions,
      );

      this.autocompleteAddress.addListener('place_changed', this.addressChanged.bind(this));
    }

    if (this.hasHiddenTarget) {
      this.hiddenTarget.value = this.addressFieldTarget.value;
    }

    if (this.hasNeighborhoodFieldTarget && !this.autocompleteNeighborhood) {
      this.autocompleteNeighborhood = new google.maps.places.Autocomplete(
        this.neighborhoodFieldTarget,
        neighborhoodOptions,
      );
      this.autocompleteNeighborhood.addListener(
        'place_changed',
        this.neighborhoodChanged.bind(this),
      );
    }
  }

  neighborhoodChanged() {
    const place = this.autocompleteNeighborhood.getPlace();

    if (this.hasAddressComponentsTarget) {
      this.addressComponentsTarget.value = JSON.stringify({
        zones: place.address_components,
      });
    }

    this.updateCountry(place);
    this.updateDepartment(place);
    this.updateLocality(place);
    this.updateNeighborhood(place);
  }

  addressChanged() {
    const place = this.autocompleteAddress.getPlace();

    if (place.geometry) {
      this.latitudeTarget.value = place.geometry.location.lat();
      this.longitudeTarget.value = place.geometry.location.lng();

      if (this.hasHiddenTarget) {
        this.hiddenTarget.value = place.formatted_address;
      }
    }
  }

  updateLocality(place) {
    const filterOptions = (component) => component.types.includes('locality');
    const localityInfo = place.address_components.filter(filterOptions);

    const locality = localityInfo.length ? localityInfo[0].long_name : null;

    this.localityTarget.value = locality;
  }

  updateCountry(place) {
    const filterOptions = (component) => component.types.includes('country');
    const countryInfo = place.address_components.filter(filterOptions);

    const country = countryInfo.length ? countryInfo[0].long_name : '';

    this.countryTarget.value = country;
  }

  updateDepartment(place) {
    const filterOptions = (component) => component.types.includes('administrative_area_level_1');
    const departmentInfo = place.address_components.filter(filterOptions);

    const department = departmentInfo.length ? departmentInfo[0].long_name : '';

    this.departmentTarget.value = department;
  }

  updateNeighborhood(place) {
    const neighborhoodInfo = place.address_components;
    const neighborhood = neighborhoodInfo.length ? neighborhoodInfo[0].long_name : '';
    this.neighborhoodTarget.value = neighborhood;
  }
}
