import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'propertyId',
    'propertyStatus',
    'propertyType',
    'propertyOperation',
    'inquiryId',
    'inquiryStatus',
    'inquirySpecificStatus',
    'propertyOrder',
    'inquiryOrder',
  ];

  addPropertiesFiltersParams(event) {
    const { currentTarget } = event;

    const oldParamsValues = this.getPropertyParamsOldValues();
    const newParamsValues = this.updatePropertyParamsValues(currentTarget, oldParamsValues);

    let newUrl = new URL(window.location.origin + window.location.pathname);
    newUrl = this.appendFilterParams(newUrl, newParamsValues);
    newUrl = this.appendOrderParams(newUrl);

    window.location.href = newUrl.href;
  }

  getPropertyParamsOldValues() {
    const id = this.propertyIdTarget.value;
    const status = this.propertyStatusTarget.value;
    const operation = this.propertyOperationTarget.value;

    return { id, status, operation };
  }

  updatePropertyParamsValues(currentTarget, params) {
    const filterType = currentTarget.getAttribute('data-filter');
    const filterValue = currentTarget.getAttribute('data-value');

    switch (filterType) {
      case 'status':
        params.status = filterValue;
        break;
      case 'operation':
        params.operation = filterValue;
        break;
      case 'id':
        params.id = this.propertyIdTarget.value;
        break;
      default:
        break;
    }
    return params;
  }

  appendFilterParams(newUrl, params) {
    if (params.id !== '') {
      newUrl.searchParams.append('id', params.id);
    }

    if (params.status !== '') {
      newUrl.searchParams.append('status', params.status);
    }

    if (params.operation !== '') {
      newUrl.searchParams.append('operation', params.operation);
    }

    const typeForSearch = this.getTypesArray();
    if (typeForSearch.length > 0) {
      typeForSearch.forEach((type) => {
        newUrl.searchParams.append('type[]', type);
      });
    }

    return newUrl;
  }

  appendOrderParams(newUrl) {
    const orderBy = this.propertyOrderTarget.value;
    if (orderBy !== '') {
      newUrl.searchParams.append('order_by', orderBy);
    }
    return newUrl;
  }

  filterInquiries(event) {
    const { currentTarget } = event;
    let idForSearch = this.inquiryIdTarget.value;
    let statusForSearch = this.inquiryStatusTarget.value;
    let specificStatusForSearch = this.inquirySpecificStatusTarget.value;
    const orderBy = this.inquiryOrderTarget.value;

    const filterType = currentTarget.getAttribute('data-filter');
    const filterValue = currentTarget.getAttribute('data-value');
    let specificStatusChanged = false;
    let statusChanged = false;

    switch (filterType) {
      case 'specificStatus':
        specificStatusForSearch = filterValue;
        specificStatusChanged = true;
        break;
      case 'status':
        statusForSearch = filterValue;
        statusChanged = true;
        break;
      case 'id':
        idForSearch = this.inquiryIdTarget.value;
        break;
      default:
        break;
    }

    const newUrl = new URL(window.location.origin + window.location.pathname);
    if (idForSearch !== '') {
      newUrl.searchParams.append('id', idForSearch);
    }
    if (statusForSearch !== '') {
      newUrl.searchParams.append('status', statusForSearch);
    }
    if (specificStatusForSearch !== '' && !statusChanged) {
      newUrl.searchParams.append('specific_status', specificStatusForSearch);
    }
    if (specificStatusChanged) {
      newUrl.searchParams.set('status', 'attended');
    }
    if (orderBy !== '') {
      newUrl.searchParams.append('order_by', orderBy);
    }

    window.location.href = newUrl.href;
  }

  getTypesArray() {
    const typesForSearch = [];

    this.propertyTypeTargets.forEach((typeCheckbox) => {
      if (typeCheckbox.checked) {
        typesForSearch.push(typeCheckbox.getAttribute('data-value'));
      }
    });

    return typesForSearch;
  }
}
