/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'inputField',
    'country',
    'countryName',
    'department',
    'departmentName',
    'locality',
    'localityName',
    'neighborhood',
    'neighborhoodName',
  ];

  connect() {
    if (google === undefined) {
      $(this.bindGoogleMapsApiToField());
    } else {
      this.bindGoogleMapsApiToField();
    }
  }

  bindGoogleMapsApiToField() {
    const neighborhoodOptions = {
      componentRestrictions: { country: 'uy' },
      types: [
        'neighborhood',
        'natural_feature',
        'sublocality',
        'administrative_area_level_2',
        'locality',
      ],
    };
    if (this.hasInputFieldTarget) {
      this.autocompleteNeighborhood = new google.maps.places.Autocomplete(
        this.inputFieldTarget,
        neighborhoodOptions,
      );
      this.autocompleteNeighborhood.addListener('place_changed', () => this.neighborhoodSelected());
    }
  }

  neighborhoodSelected() {
    const place = this.autocompleteNeighborhood.getPlace();

    this.updateCountry(place);
    this.updateDepartment(place);
    this.updateLocality(place);
    this.updateNeighborhood(place);
  }

  updateLocality(place) {
    const filterOptions = (component) => component.types.includes('locality');
    const localityInfo = place.address_components.filter(filterOptions);

    const locality = localityInfo.length ? JSON.stringify(localityInfo[0]) : null;

    this.localityTarget.value = locality;
    this.localityNameTarget.value = localityInfo.length ? localityInfo[0].long_name : null;
  }

  updateCountry(place) {
    const filterOptions = (component) => component.types.includes('country');
    const countryInfo = place.address_components.filter(filterOptions);

    const country = countryInfo.length ? JSON.stringify(countryInfo[0]) : '';

    this.countryTarget.value = country;
    this.countryNameTarget.value = countryInfo[0].long_name;
  }

  updateDepartment(place) {
    const filterOptions = (component) => component.types.includes('administrative_area_level_1');
    const departmentInfo = place.address_components.filter(filterOptions);

    const department = departmentInfo.length ? JSON.stringify(departmentInfo[0]) : '';

    this.departmentTarget.value = department;
    this.departmentNameTarget.value = departmentInfo[0].long_name;
  }

  updateNeighborhood(place) {
    const neighborhoodInfo = place.address_components;
    const neighborhood = neighborhoodInfo.length ? JSON.stringify(neighborhoodInfo[0]) : '';

    this.neighborhoodTarget.value = neighborhood;
    this.neighborhoodNameTarget.value = neighborhoodInfo[0].long_name;
  }
}
