import { Controller } from '@hotwired/stimulus';
import { Turbo } from '@hotwired/turbo-rails';

export default class extends Controller {
  connect() {
    $(this.element).on('turbo:submit-end', (event) => {
      if (event.detail.success) {
        Turbo.visit(event.detail.fetchResponse.response.url);
      }
    });
  }
}
