import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'neighborhood',
    'departmentSelect',
    'filtersForm',
    'neighborhoodSection',
    'propertyTypeCheckboxes',
    'operationKind',
    'neighborhoodCheckbox',
  ];

  connect() {
    if (this.isPropertiesForm()) {
      this.changePropertiesFormPath();
    }
    this.filterByDepartment();
  }

  isPropertiesForm() {
    const actionAttribute = this.filtersFormTarget.getAttribute('action');
    return actionAttribute === '/ventas' || actionAttribute === '/alquileres';
  }

  changePropertiesFormPath() {
    let path = this.addPropertyTypesToPath();
    path = this.addLocationToPath(path);

    this.filtersFormTarget.setAttribute('action', path);
  }

  addPropertyTypesToPath() {
    const selectedPropertyTypes = this.getSelectedPropertyTypes();

    let pathPropertyName = 'propiedades';
    if (selectedPropertyTypes.length === 1) {
      [pathPropertyName] = selectedPropertyTypes;
    }

    return `/${this.operation()}-${pathPropertyName}`;
  }

  getSelectedPropertyTypes() {
    return this.propertyTypeCheckboxesTargets
      .filter((checkbox) => checkbox.checked)
      .map((checkbox) => {
        switch (checkbox.name) {
          case 'filter[type[house]]':
            return 'casas';
          case 'filter[type[apartment]]':
            return 'apartamentos';
          case 'filter[type[terrain]]':
            return 'terrenos_o_lotes';
          case 'filter[type[farmland]]':
            return 'chacras_o_campos';
          case 'filter[type[commercial_space]]':
            return 'locales_comerciales';
          case 'filter[type[office]]':
            return 'oficinas';
          case 'filter[type[industrial_space]]':
            return 'locales_industriales_o_galpones';
          default:
            return '';
        }
      });
  }

  operation() {
    const actionAttribute = this.filtersFormTarget.getAttribute('action');
    return actionAttribute.split('-')[0].includes('alquiler') ? 'alquiler' : 'venta';
  }

  addLocationToPath(path) {
    const selectedDepartmentName = this.getSelectedDepartmentName();
    if (selectedDepartmentName) {
      path += `-${selectedDepartmentName}`;

      if (this.anyNeighborhoodChecked()) {
        path = this.addNeighborhoodsToPath(path);
      }
    }
    return path;
  }

  getSelectedDepartmentName() {
    const departmentOptions = this.departmentSelectTarget.options;
    const selectedDepartmentIndex = this.departmentSelectTarget.selectedIndex;
    const selectedDepartmentOption = departmentOptions[selectedDepartmentIndex];
    return selectedDepartmentOption.getAttribute('data-slug');
  }

  anyNeighborhoodChecked() {
    return this.neighborhoodCheckboxTargets.some((value) => value.checked);
  }

  addNeighborhoodsToPath(path) {
    const checkedNeighborhoods = this.neighborhoodCheckboxTargets.filter((value) => value.checked);

    let neighborhood;
    for (let i = 0; i < checkedNeighborhoods.length; i += 1) {
      neighborhood = checkedNeighborhoods[i];
      path = this.addNeighborhoodToPath(neighborhood, path);
    }

    return path;
  }

  // eslint-disable-next-line class-methods-use-this
  addNeighborhoodToPath(neighborhood, path) {
    const selectedNeighborhoodName = neighborhood.getAttribute('data-slug');

    if (selectedNeighborhoodName) {
      path += `-${selectedNeighborhoodName}`;
    }
    return path;
  }

  submitForm() {
    this.filtersFormTarget.submit();
  }

  departmentChange() {
    this.filterByDepartment();
  }

  filterByDepartment() {
    const selectedDepartmentId = this.departmentSelectTarget.value;

    const noDepartmentIsSelected = selectedDepartmentId === '';
    if (noDepartmentIsSelected) {
      this.hideNeighborhoodSelect();
    } else {
      this.showNeighborhoodSelect();
      this.hideNeighborhoodsFromOtherDepartments(selectedDepartmentId);
    }
  }

  hideNeighborhoodSelect() {
    this.neighborhoodSectionTarget.classList.add('d-none');
  }

  showNeighborhoodSelect() {
    this.neighborhoodSectionTarget.classList.remove('d-none');
  }

  hideNeighborhoodsFromOtherDepartments(selectedDepartmentId) {
    this.neighborhoodTargets.forEach((element) => {
      if (element.classList.contains(selectedDepartmentId)) {
        element.disabled = false;
        element.classList.remove('d-none');
      } else {
        element.disabled = true;
        element.classList.add('d-none');
      }
    });
  }

  changeHomeFormPath() {
    const operationKind = this.operationKindTarget.getAttribute('data-value');
    const path = operationKind === 'rent' ? 'alquileres' : 'ventas';
    this.filtersFormTarget.setAttribute('action', path);
  }
}
