/* eslint-disable no-case-declarations */

import { Controller } from '@hotwired/stimulus';

const LONG_DATE = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
};

const DATE_TIME = {
  year: 'numeric',
  month: 'long',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
};

const NUMERIC_DATE = {
  year: 'numeric',
  month: 'numeric',
  day: 'numeric',
};

const TIME = {
  hour: 'numeric',
  minute: 'numeric',
};

const MS_PER_SECOND = 1000;

const MS_PER_MINUTE = MS_PER_SECOND * 60;

const MS_PER_HOUR = MS_PER_MINUTE * 60;

const MS_PER_DAY = MS_PER_HOUR * 24;

const getDate = (value) => {
  const jsonDateString = JSON.parse(value);
  return new Date(jsonDateString);
};

const getTimeSinceString = (days) => {
  let stringToBeShown = '';
  switch (true) {
    case days === 0:
      stringToBeShown = 'hoy';
      break;

    case days === 1:
      stringToBeShown = 'ayer';
      break;

    case days < 7:
      stringToBeShown = `hace ${days} días`;
      break;

    case days < 30:
      const weeks = Math.floor(days / 7);
      stringToBeShown = `hace ${weeks} semana${weeks > 1 ? 's' : ''}`;
      break;

    case days < 365:
      const months = Math.floor(days / 30);
      stringToBeShown = `hace ${months} mes${months > 1 ? 'es' : ''}`;
      break;

    default:
      const years = Math.floor(days / 365);
      stringToBeShown = `hace ${years} año${years > 1 ? 's' : ''}`;
      break;
  }

  return stringToBeShown;
};

export default class extends Controller {
  static targets = [
    'dateText',
    'timeText',
    'longDateText',
    'timeSinceText',
    'remainingTimeCounterText',
  ];

  static values = {
    date: String,
    remainingTime: String,
  };

  dateTextTargetConnected(target) {
    const date = getDate(this.dateValue);
    const options = NUMERIC_DATE;
    $(target).text(date.toLocaleString('es-AR', options));
  }

  longDateTextTargetConnected(target) {
    const date = getDate(this.dateValue);
    const options = LONG_DATE;
    $(target).text(date.toLocaleString('es-AR', options));
  }

  timeTextTargetConnected(target) {
    const date = getDate(this.dateValue);
    const options = TIME;
    $(target).text(`${date.toLocaleString('es-AR', options)} hs.`);
  }

  dateTimeTextTargetConnected(target) {
    const date = getDate(this.dateValue);
    const options = DATE_TIME;
    $(target).text(date.toLocaleString('es-AR', options));
  }

  timeSinceTextTargetConnected(target) {
    const date = getDate(this.dateValue);
    const now = new Date();

    const msSince = Math.abs(date - now);
    const daysSince = Math.floor(msSince / MS_PER_DAY);

    const timeSinceString = getTimeSinceString(daysSince);

    $(target).text(timeSinceString);
  }

  remainingTimeCounterTextTargetConnected(target) {
    const remainingTimeInMs = parseInt(this.remainingTimeValue, 10);
    if (remainingTimeInMs > 0) {
      let pluralStringAux;
      let shownString;

      const remainingTimeInDays = Math.floor(remainingTimeInMs / MS_PER_DAY);
      const remainingTimeInHours = Math.floor(remainingTimeInMs / MS_PER_HOUR);
      const remainingTimeInMinutes = Math.floor(remainingTimeInMs / MS_PER_MINUTE);

      switch (true) {
        case remainingTimeInDays > 0:
          pluralStringAux = remainingTimeInDays > 1 ? 's' : '';
          shownString = `${remainingTimeInDays} día${pluralStringAux}`;
          break;

        case remainingTimeInHours > 0:
          pluralStringAux = remainingTimeInHours > 1 ? 's' : '';
          shownString = `${remainingTimeInHours} hora${pluralStringAux}`;
          break;

        default:
          if (remainingTimeInMinutes > 0) {
            pluralStringAux = remainingTimeInMinutes > 1 ? 's' : '';
            shownString = `${remainingTimeInMinutes} minuto${pluralStringAux}`;
          } else {
            shownString = 'menos de un minuto';
          }
          break;
      }
      $(target).text(shownString);
    } else {
      $(target).text('ya caducó');
    }
  }
}
