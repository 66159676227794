/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import * as L from 'leaflet';

const htmlPopUp = (
  name,
  link,
  picture,
  localization,
  pricing,
  dorms,
  deliveryDate,
) => `<div style="height: 400px; width: 300px;">
        <a href=${link} target="_blank" class="text-primary">
          <div style="margin-left:auto; margin-right:auto; width: 300px;">
            <img src=${picture} style="width: 300px; height: 250px; border: 0px;">
            </img>
          </div>
          <div class="card-body position-relative">
            <h5 class="h6 mb-1 fs-sm">${name}</h5>
            <p class="mt-0 mb-2 fs-xs text-muted">${localization}</p>
            <div class="fs-sm fw-bold">
              <i class="fi-cash me-2 fs-base align-middle opacity-70"></i>
              <span class="text-muted">Desde </span>
              ${pricing}
            </div>
          </div>
          <div class="card-footer d-flex align-items-center justify-content-between text-nowrap py-0 my-0">
            <span class="d-inline-block mx-1 pe-2 fs-xs">
                ${dorms}
            </span>
            <p class="fs-xs ps-2 mb-0 pb-0 border-start">
              ${deliveryDate}
            </p>
          </div>
        </a>
      </div>`;

const defaultIcon = L.icon({
  iconUrl: '/marker-icon.png',
  iconSize: [20, 30],
  iconAnchor: [2, 2],
  popupAnchor: [0, -2],
});

export default class extends Controller {
  static targets = [];

  static values = {
    projects: Object,
  };

  connect() {
    this.initializeProjectMap();
  }

  initializeProjectMap() {
    const leafletMap = L.map('projects_map').setView(new L.LatLng(-34.8, -56.175), 11);
    const layerGroup = L.layerGroup().addTo(leafletMap);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(leafletMap);
    const { projects } = this.projectsValue;

    projects.forEach((project) => this.addProjectCirlce(project, layerGroup));
  }

  addProjectCirlce(project, layerGroup) {
    const { latitude, longitude, name, localization, link, picture, pricing, dorms, deliveryDate } =
      project;
    const latLng = new L.LatLng(latitude, longitude);
    const circle = L.circle(latLng, { icon: defaultIcon, radius: 120 }).addTo(layerGroup);
    circle.bindPopup(
      htmlPopUp(name, link, picture, localization, pricing || '', dorms, deliveryDate),
    );
    circle.addTo(layerGroup);
  }
}
