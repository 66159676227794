import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = [
    'department',
    'locality',
    'neighborhood',
    'departmentSelect',
    'localitySelect',
    'countrySelect',
    'defaultDepartment',
    'defaultLocality',
    'defaultNeighborhood',
  ];

  connect() {
    this.filterByCountry();
    this.filterByDepartment();
    this.filterByLocality();
  }

  countryChange() {
    this.filterByCountry();
    this.defaultDepartmentTarget.selected = true;
    this.filterByDepartment();
    this.defaultLocalityTarget.selected = true;
    this.filterByLocality();
    this.defaultNeighborhoodTarget.selected = true;
  }

  departmentChange() {
    this.filterByDepartment();
    this.defaultLocalityTarget.selected = true;
    this.filterByLocality();
    this.defaultNeighborhoodTarget.selected = true;
  }

  localityChange() {
    this.filterByLocality();
    this.defaultNeighborhoodTarget.selected = true;
  }

  filterByCountry() {
    const selectedCountryId = this.countrySelectTarget.value;
    this.departmentTargets.forEach((element) => {
      if (element.classList.contains(selectedCountryId) || selectedCountryId === 0) {
        element.disables = false;
        element.classList.remove('d-none');
      } else {
        element.disables = true;
        element.classList.add('d-none');
      }
    });
  }

  filterByDepartment() {
    const selectedDepartmentId = this.departmentSelectTarget.value;
    this.localityTargets.forEach((element) => {
      if (element.classList.contains(selectedDepartmentId) || selectedDepartmentId === 0) {
        element.disables = false;
        element.classList.remove('d-none');
      } else {
        element.disables = true;
        element.classList.add('d-none');
      }
    });
  }

  filterByLocality() {
    const selectedLocalityId = this.localitySelectTarget.value;
    this.neighborhoodTargets.forEach((element) => {
      if (element.classList.contains(selectedLocalityId) || selectedLocalityId === 0) {
        element.disables = false;
        element.classList.remove('d-none');
      } else {
        element.disables = true;
        element.classList.add('d-none');
      }
    });
  }
}
