import { Controller } from '@hotwired/stimulus';
import * as FilePond from 'filepond';
import FilePondPluginImageTransform from 'filepond-plugin-image-transform';
import FilePondPluginImageResize from 'filepond-plugin-image-resize';
import FilePondPluginImageCrop from 'filepond-plugin-image-crop';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileRename from 'filepond-plugin-file-rename';

const sanitizeString = (file) => {
  const sanitizedName = file.name.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  return sanitizedName;
};

export default class extends Controller {
  static targets = ['mainInput'];

  static values = {
    pictures: Array,
    propertyId: String,
  };

  connect() {
    const propertyId = this.propertyIdValue;

    FilePond.setOptions({
      server: {
        process: `/properties/${propertyId}/pictures`,
        revert: `/properties/${propertyId}/pictures/`,
        load: async (pictureId, load, error, progress, abort) => {
          const pictures = this.picturesValue;
          const pictureToFetch = pictures.find((picture) => picture.id === pictureId);
          const { url } = pictureToFetch;
          const response = await fetch(url, { cache: 'no-cache' });
          const fileBlob = await response.blob();
          const fileName = url.split('/').slice(-1)[0];
          const newFile = new File([fileBlob], fileName, { type: fileBlob.type });
          load(newFile);
          return {
            abort: () => {
              abort();
            },
          };
        },
      },
    });

    FilePond.registerPlugin(FilePondPluginFileValidateType);
    FilePond.registerPlugin(FilePondPluginFileValidateSize);
    FilePond.registerPlugin(FilePondPluginImagePreview);
    FilePond.registerPlugin(FilePondPluginImageCrop);
    FilePond.registerPlugin(FilePondPluginImageResize);
    FilePond.registerPlugin(FilePondPluginImageTransform);
    FilePond.registerPlugin(FilePondPluginFileRename);

    this.pond = FilePond.create(this.mainInputTarget, {
      allowReorder: true,
      fileRenameFunction: (file) => sanitizeString(file),
      files: this.getPersistedPictures(),
      maxFiles: 30,
      storeAsFile: false,
      styleItemPanelAspectRatio: 3 / 4,
    });
  }

  getPersistedPictures() {
    return this.picturesValue.map(this.getPersistedPicture);
  }

  // eslint-disable-next-line class-methods-use-this
  getPersistedPicture(picture) {
    return {
      source: picture.id,
      options: {
        type: 'local',
        metadata: {
          poster: picture.url,
        },
      },
    };
  }
}
