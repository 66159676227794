/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';

const MAX_DESCRIPTION_LENGTH = 500;

export default class extends Controller {
  static targets = ['remainingLength', 'numericInput'];

  static values = {
    startingLength: Number,
  };

  changeDescription(event) {
    const descriptionInput = event.target;
    this.setDescriptionRemainingLength(descriptionInput.textLength);
  }

  remainingLengthTargetConnected() {
    const descriptionStartingLength = this.startingLengthValue;
    this.setDescriptionRemainingLength(descriptionStartingLength);
  }

  setDescriptionRemainingLength = (descriptionLength) => {
    const remainingLength = MAX_DESCRIPTION_LENGTH - descriptionLength;

    const targetElement = this.remainingLengthTarget;
    let stringShown;
    if (remainingLength > 0) {
      stringShown = `${remainingLength} carácteres restantes`;
    } else {
      stringShown = `${Math.abs(remainingLength)} carácteres sobrantes`;
    }
    $(targetElement).text(stringShown);
  };

  filterNonNumericInput(event) {
    if (event.key === ',' || event.key === '.' || event.key === 'e') {
      event.preventDefault();
    }
  }
}
