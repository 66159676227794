/* eslint-disable operator-linebreak */

import { Controller } from '@hotwired/stimulus';
import noUiSlider from 'nouislider';

export default class extends Controller {
  static targets = [
    'minUsdValue',
    'maxUsdValue',
    'usdChange',
    'slider',
    'minInput',
    'maxInput',
    'sliderContainer',
  ];

  static values = {
    startMin: Number,
    startMax: Number,
    min: Number,
    max: Number,
    step: Number,
  };

  updateSlider(startPrice, endPrice, currency) {
    const rangeSlider = this.sliderTarget;
    const [selectedStart, selectedEnd] = rangeSlider.noUiSlider.get();

    const convertedSelectedStart =
      currency === 'uyu'
        ? parseInt(selectedStart.replace('$', ''), 10) * this.usdChangeTarget.value
        : parseInt(selectedStart.replace('$', ''), 10) / this.usdChangeTarget.value;

    const convertedSelectedEnd =
      currency === 'uyu'
        ? parseInt(selectedEnd.replace('$', ''), 10) * this.usdChangeTarget.value
        : parseInt(selectedEnd.replace('$', ''), 10) / this.usdChangeTarget.value;

    rangeSlider.noUiSlider.updateOptions({
      range: {
        min: startPrice,
        max: endPrice,
      },
      start: [convertedSelectedStart, convertedSelectedEnd],
    });
  }

  usdSelected() {
    this.updateSlider(
      parseInt(this.minUsdValueTarget.value, 10),
      parseInt(this.maxUsdValueTarget.value, 10),
      'usd',
    );
  }

  uyuSelected() {
    this.updateSlider(
      this.minUsdValueTarget.value * this.usdChangeTarget.value,
      this.maxUsdValueTarget.value * this.usdChangeTarget.value,
      'uyu',
    );
  }

  createSlider() {
    const options = {
      dataStartMin: this.startMinValue,
      dataStartMax: this.startMaxValue,
      dataMin: this.minValue,
      dataMax: this.maxValue,
      dataStep: this.stepValue,
    };
    const start = options.dataStartMax
      ? [options.dataStartMin, options.dataStartMax]
      : [options.dataStartMin];
    const connect = options.dataStartMax ? true : 'lower';

    noUiSlider.create(this.sliderTarget, {
      start,
      connect,
      step: options.dataStep,
      tooltips: true,
      range: {
        min: options.dataMin,
        max: options.dataMax,
      },
      format: {
        to: (value) => parseInt(value, 10).toString(),
        from: (value) => Number(value),
      },
    });

    this.sliderTarget.noUiSlider.on('update', (values, handle) => {
      let value = values[handle];
      value = value.replace(/\D/g, '');

      if (handle) {
        if (this.maxInputTarget) {
          this.maxInputTarget.value = Math.round(value);
        }
      } else if (this.minInputTarget) {
        this.minInputTarget.value = Math.round(value);
      }
    });
  }

  sliderContainerTargetConnected() {
    this.createSlider();
  }

  sliderContainerTargetDisconnected() {
    this.sliderTarget.noUiSlider.destroy();
  }

  handleMax(event) {
    this.sliderTarget.noUiSlider.set([null, event.target.value]);
  }

  handleMin(event) {
    this.sliderTarget.noUiSlider.set([event.target.value, null]);
  }
}
