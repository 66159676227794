import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inquirySpecificStatus', 'inquiryOrder'];

  filterInquiries(event) {
    const { currentTarget } = event;
    let specificStatusForSearch = this.inquirySpecificStatusTarget.value;
    const orderBy = this.inquiryOrderTarget.value;

    const filterType = currentTarget.getAttribute('data-filter');
    const filterValue = currentTarget.getAttribute('data-value');

    if (filterType === 'specificStatus') {
      specificStatusForSearch = filterValue;
    }

    const newUrl = new URL(window.location.origin + window.location.pathname);
    if (specificStatusForSearch !== '') {
      newUrl.searchParams.append('specific_status', specificStatusForSearch);
    }
    if (orderBy !== '') {
      newUrl.searchParams.append('order_by', orderBy);
    }

    window.location.href = newUrl.href;
  }
}
