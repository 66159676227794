/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['inputField', 'longNameField', 'shortNameField', 'typesField'];

  connect() {
    if (google === undefined) {
      $(this.bindGoogleMapsApiToField());
    } else {
      this.bindGoogleMapsApiToField();
    }
  }

  bindGoogleMapsApiToField() {
    const neighborhoodOptions = {
      componentRestrictions: { country: 'uy' },
      types: [
        'neighborhood',
        'natural_feature',
        'sublocality',
        'administrative_area_level_2',
        'locality',
      ],
    };

    if (this.hasInputFieldTarget) {
      this.autocompleteNeighborhood = new google.maps.places.Autocomplete(
        this.inputFieldTarget,
        neighborhoodOptions,
      );
      this.autocompleteNeighborhood.addListener('place_changed', () => this.zoneSelected());
    }
  }

  zoneSelected() {
    const place = this.autocompleteNeighborhood.getPlace();

    const selectedZone = place.address_components[0] ?? {};
    this.fillInputFields(selectedZone);
  }

  fillInputFields(selectedZone) {
    if (this.hasLongNameFieldTarget) {
      this.longNameFieldTarget.value = selectedZone.long_name;
    }
    if (this.hasShortNameFieldTarget) {
      this.shortNameFieldTarget.value = selectedZone.short_name;
    }
    if (this.hasTypesFieldTarget) {
      this.typesFieldTarget.value = JSON.stringify(selectedZone.types);
    }
  }
}
