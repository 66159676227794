/* eslint-disable no-undef */

import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
  static targets = ['latitude', 'longitude'];

  connect() {
    $(document).on('turbo:load', () => {
      this.initializeStreetView();
    });
  }

  initializeStreetView() {
    const latitude = this.latitudeTarget.value;
    const longitude = this.longitudeTarget.value;

    const addressPosition = { lat: parseFloat(latitude), lng: parseFloat(longitude) };
    this.panorama = new google.maps.StreetViewPanorama(document.getElementById('pano'), {
      position: addressPosition,
      pov: {
        heading: 34,
        pitch: 10,
      },
    });
  }
}
