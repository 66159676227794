/* eslint-disable operator-linebreak */
/* eslint-disable object-curly-newline */
/* eslint-disable quotes */
/* eslint-disable class-methods-use-this */
import { Controller } from '@hotwired/stimulus';
import * as L from 'leaflet';

const htmlPopUp = (
  title,
  link,
  picture,
  localization,
  pricing,
  footer,
) => `<div style="height: 330px; width: 300px;">
        <a href=${link} target="_blank" class="text-primary">
          <div style="margin-left:auto; margin-right:auto; width: 300px;">
            <img src=${picture} style="width: 300px; border: 0px;">
            </img>
          </div>
          <div class="card-body position-relative pb-3">
            <h5 class="h6 mb-1 fs-sm">${title}</h5>
            <p class="mt-0 mb-2 fs-xs text-muted">${localization}</p>
            <div class="fs-sm fw-bold pb-2">
              <i class="fi-cash me-2 fs-base align-middle opacity-70"></i>
              ${pricing}
            </div>
            <div class="d-flex border-top pt-2 text-nowrap">
              ${footer}
            </div>
          </div>
        </a>
      </div>`;

const defaultIcon = L.icon({
  iconUrl: '/marker-icon.png',
  iconSize: [18, 20],
  iconAnchor: [2, 2],
  popupAnchor: [0, -2],
});

export default class extends Controller {
  static targets = [];

  static values = {
    properties: Object,
  };

  connect() {
    this.initializePropertyMap();
  }

  initializePropertyMap() {
    const leafletMap = L.map('properties_map').setView(new L.LatLng(-34.8, -56.175), 11);
    const layerGroup = L.layerGroup().addTo(leafletMap);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(leafletMap);
    const { properties } = this.propertiesValue;
    properties.forEach((property) => this.addPropertyCircle(property, layerGroup));
  }

  addPropertyCircle(property, layerGroup) {
    const { latitude, longitude, title, localization, link, picture, pricing, footer } = property;
    const latLng = new L.LatLng(latitude, longitude);
    const circle = L.circle(latLng, { icon: defaultIcon, radius: 50 }).addTo(layerGroup);
    circle.bindPopup(htmlPopUp(title, link, picture, localization, pricing || '', footer));
    circle.addTo(layerGroup);
  }
}
