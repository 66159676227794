import { Controller } from '@hotwired/stimulus';

const OPERATIONS = {
  sell: 'sell',
  rent: 'rent',
};

export default class extends Controller {
  static targets = ['form', 'departmentField'];

  connect() {
    this.setFormAction();
  }

  changeOperation(event) {
    this.operation = $(event.currentTarget).data('value');
    this.setFormAction();
  }

  changeDepartment(event) {
    this.departmentName = $(event.currentTarget).data('department-name');
    this.setFormAction();
  }

  changePropertyType(event) {
    this.propertyType = $(event.currentTarget).data('value');
    this.setFormAction();
  }

  setFormAction() {
    this.formTarget.action = this.formAction();
  }

  propertyTypeSlug() {
    switch (this.propertyType) {
      case 'house':
        return 'casas';
      case 'apartment':
        return 'apartamentos';
      case 'terrain':
        return 'terrenos_o_lotes';
      case 'farmland':
        return 'chacras_o_campos';
      case 'commercial_space':
        return 'locales_comerciales';
      case 'office':
        return 'oficinas';
      case 'industrial_space':
        return 'locales_industriales_o_galpones';
      default:
        return 'propiedades';
    }
  }

  formAction() {
    if (!(this.departmentName || this.propertyType)) {
      return this.operation === OPERATIONS.rent ? '/alquileres' : '/ventas';
    }

    let url = this.operation === OPERATIONS.rent ? '/alquiler' : '/venta';

    url = `${url}-${this.propertyTypeSlug()}`;

    if (!this.departmentName) {
      return url;
    }

    return `${url}-${this.departmentName}`;
  }
}
