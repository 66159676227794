import * as L from 'leaflet';

$(document).on('turbo:load', () => {
  const mapContainer = document.getElementById('map');
  if (mapContainer) {
    const leafletMap = L.map('map').setView(new L.LatLng(-32.5, -55.8), 6);
    const layerGroup = L.layerGroup().addTo(leafletMap);

    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      attribution:
        '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors',
    }).addTo(leafletMap);

    const defaultIcon = L.icon({
      iconUrl: '/marker-icon.png',
      iconSize: [18, 20],
      iconAnchor: [2, 2],
      popupAnchor: [0, -2],
    });

    let latitude = document.getElementById('property_latitude');
    let longitude = document.getElementById('property_longitude');

    if (latitude == null && longitude == null) {
      latitude = document.getElementById('project_latitude');
      longitude = document.getElementById('project_longitude');
    }

    if (latitude.value) {
      leafletMap.flyTo([latitude.value, longitude.value], 16);
      layerGroup.clearLayers();
      L.marker([latitude.value, longitude.value], { icon: defaultIcon }).addTo(layerGroup);
    }

    const observer = new MutationObserver(() => {
      leafletMap.flyTo([latitude.value, longitude.value], 16);
      layerGroup.clearLayers();

      L.marker([latitude.value, longitude.value], { icon: defaultIcon }).addTo(layerGroup);
    });
    observer.observe(latitude, { attributes: true });
  }
});
